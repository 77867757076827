<template>
  <div class="container-fluid rounded bg-white pt-4">
    <div class="row mb-3">
      <div class="col-12 d-flex">
        <div class="ml-auto">
          <modal-actions
            v-if="$route.params.fromInitiative"
            @close="
              $router.push({
                name: 'show-initiative',
                params: {
                  id: INITIATIVE.id,
                  slug: INITIATIVE.slug,
                },
              })
            "
          />
          <modal-actions v-else @close="$router.go(-1)" />
        </div>
      </div>
    </div>
    <!-- {{ $route.params.fromInitiative }} -->
    <div class="row">
      <div class="col-12 d-flex flex-wrap justify-content-between">
        <span class="h2 text-secondary">
          <!-- <i :class="$route.meta.iconClass"></i>  -->
          Nouvelle tâche</span
        >
        <div>
          <b-button pill @click.prevent="saveItem">Confirmer</b-button>
        </div>
      </div>
    </div>
    <div id="add-page" class="mt-3 col-12 row p-0 mx-auto">
      <div class="col-12 col-md-4 p-1">
        <form class="shdow mb-2 h-100" @submit.prevent="saveItem">
          <div class="row">
            <div class="col-12 d-flex flex-wrap justify-content-between">
              <p class="h4 text-secondary my-auto mr-auto">
                Informations générales
              </p>
            </div>
          </div>
          <hr class="mt-1 mb-3 bg-secondary" />
          <div class="row">
            <div class="col-12">
              <b-form-group
                label="Initiative:"
                class="font-weight-bold"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  :class="{
                    'is-invalid':
                      $v.selectedInitiative.$error &&
                      $v.selectedInitiative.$dirty,
                  }"
                >
                  <v-select
                    :options="initiativesList"
                    placeholder="Initiative"
                    label="text"
                    class="bg-transparent"
                    :disabled="$route.params.fromInitiative"
                    @input="selectedPhase = null"
                    v-model="selectedInitiative"
                    :reduce="(initiative) => initiative.value"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.selectedInitiative.$dirty"
                >
                  {{
                    !$v.selectedInitiative.required ? "Champ obligatoire" : ""
                  }}
                </span>
                <!--  -->
              </b-form-group>
              <b-form-group
                label="Phase:"
                class="font-weight-bold"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  :class="{
                    'is-invalid':
                      $v.selectedPhase.$error && $v.selectedPhase.$dirty,
                  }"
                >
                  <v-select
                    :options="phasesList"
                    placeholder="Phase"
                    class="bg-transparent"
                    v-model="selectedPhase"
                    :disabled="$route.params.fromInitiative"
                    label="text"
                    :reduce="(phase) => phase.value"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.selectedPhase.$dirty"
                >
                  {{ !$v.selectedPhase.required ? "Champ obligatoire" : "" }}
                </span>
                <!--  -->
              </b-form-group>
              <b-form-group
                label="Type:"
                class="font-weight-bold"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  :class="{
                    'is-invalid':
                      $v.newTask.type.$error && $v.newTask.type.$dirty,
                  }"
                >
                  <v-select
                    :options="typesTask"
                    class="bg-transparent"
                    placeholder="Type"
                    v-model="newTask.type"
                    :error="true"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newTask.type.$dirty"
                >
                  {{ !$v.newTask.type.required ? "Champ obligatoire" : "" }}
                </span>
                <!--  -->
              </b-form-group>
              <!-- {{ $v.newTask.libelle }} -->

              <b-form-group
                label="Libellé:"
                class="font-weight-bold"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <b-form-input
                  type="text"
                  placeholder="Libellé"
                  v-model.trim="newTask.libelle"
                  class="font-weight-normal"
                  :class="{
                    'is-invalid':
                      $v.newTask.libelle.$error && $v.newTask.libelle.$dirty,
                  }"
                ></b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newTask.libelle.$dirty"
                >
                  {{
                    !$v.newTask.libelle.required
                      ? "Champ obligatoire"
                      : !$v.newTask.libelle.minLength
                      ? `Le champ doit contenir au moins ${$v.newTask.libelle.$params.minLength.min} caractères.`
                      : ""
                  }}
                </span>
                <!--  -->
              </b-form-group>
              <b-form-group
                label="Description:"
                class="font-weight-bold"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <textarea
                  type="text"
                  v-model="newTask.description"
                  placeholder="Description"
                  class="font-weight-normal form-control"
                />
              </b-form-group>
              <b-form-group
                label="Date de création:"
                class="font-weight-bold"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <date-picker
                  :value="new Date()"
                  disabled
                  class="col-12 p-0 font-weight-normal"
                  placeholder="Date de création"
                  format="DD/MM/YYYY"
                />
              </b-form-group>
            </div>
            <div class="col-12">
              <b-form-group
                label="Responsable:"
                class="font-weight-bold"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                >
                  <!-- :class="{
                    'is-invalid':
                      $v.selectedResponsable.$error && $v.selectedResponsable.$dirty,
                  }" -->
                  <v-select
                    :options="responsables"
                    placeholder="Responsable"
                    name="responsable"
                    class="font-weight-normal bg-transparent"
                    label="full_name"
                    :reduce="(responsable) => responsable.value"
                    v-model="selectedResponsable"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>
                <!-- ERROR MESSAGES -->
                <!-- <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.selectedResponsable.$dirty"
                >
                  {{ !$v.selectedResponsable.required ? "champ obligatoire" : "" }}
                </span> -->
                <!--  -->
              </b-form-group>
            </div>
          </div>
        </form>
      </div>

      <div class="col-12 col-md-4 p-1">
        <form class="shdow mb-2 h-100" @submit.prevent="saveItem">
          <div class="row">
            <div class="col-12 d-flex flex-wrap justify-content-between">
              <p class="h4 text-secondary my-auto mr-auto">Avancement</p>
            </div>
          </div>
          <hr class="mt-1 mb-3 bg-secondary" />
          <div class="row">
            <div class="col-12">
              <b-form-group
                label="Date de début:"
                class="font-weight-bold"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <date-picker
                  class="col-12 p-0 font-weight-normal"
                  v-model="newTask.startedAt"
                  placeholder="Date début"
                  format="DD/MM/YYYY"
                  :input-attr="{
                    class: {
                      'is-invalid':
                        $v.newTask.startedAt.$error &&
                        $v.newTask.startedAt.$dirty,
                      ' form-control': true,
                    },
                  }"
                />
                <!-- :input-attr="{ required: 'true' }" -->

                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newTask.startedAt.$dirty"
                >
                  {{
                    !$v.newTask.startedAt.required ? "Champ obligatoire" : ""
                  }}
                </span>
                <!--  -->
              </b-form-group>
              <b-form-group
                label="Date fin prév:"
                class="font-weight-bold"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <date-picker
                  class="col-12 p-0 font-weight-normal"
                  v-model="informatedDate"
                  placeholder="Date fin prév"
                  format="DD/MM/YYYY"
                  :input-attr="{
                    class: {
                      'is-invalid':
                        $v.informatedDate.$error && $v.informatedDate.$dirty,
                      ' form-control': true,
                    },
                  }"
                  :class="{
                    'is-invalid':
                      $v.informatedDate.$error && $v.informatedDate.$dirty,
                  }"
                />
                <!-- :input-attr="{ required: 'true' }" -->

                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.informatedDate.$dirty"
                >
                  {{
                    !$v.informatedDate.required
                      ? "Champ obligatoire"
                      : $v.informatedDate.required && $v.informatedDate.$error
                      ? "La date de fin ne doit pas être antérieur à la date de début"
                      : ""
                  }}
                </span>
                <!--  -->
              </b-form-group>
              <b-form-group
                label="Statut:"
                class="font-weight-bold"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  :class="{
                    'is-invalid':
                      $v.newTask.status.$error && $v.newTask.status.$dirty,
                  }"
                >
                  <v-select
                    :options="statusTasks"
                    placeholder="Statut "
                    v-model="newTask.status"
                    class="font-weight-normal bg-transparent"
                    :class="{
                      'is-invalid':
                        $v.newTask.status.$error && $v.newTask.status.$dirty,
                    }"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newTask.status.$dirty"
                >
                  {{ !$v.newTask.status.required ? "Champ obligatoire" : "" }}
                </span>
                <!--  -->
              </b-form-group>
            </div>
          </div>
        </form>
      </div>

      <div class="col-12 col-md-4 p-1">
        <form class="shdow mb-2 h-100" @submit.prevent="saveItem">
          <div class="row">
            <div class="col-12 d-flex flex-wrap justify-content-between">
              <p class="h4 text-secondary my-auto mr-auto">Livrables</p>
            </div>
          </div>
          <hr class="mt-1 mb-3 bg-secondary" />
          <div class="row">
            <div class="col-12">
              <b-form-group>
                <FilePicker
                  @change="filePickerChanger"
                  class="font-weight-normal"
                />
              </b-form-group>
            </div>
          </div>
        </form>
      </div>
    </div>
    <hr />
    <b-tabs class="tab-solid tab-solid-primary">
      <b-tab title="Contributeurs" class="col-12 p-0">
        <!-- <div class="d-flex">
          <span class="h4 text-secondary mr-auto my-auto"
            >Liste des contributeurs
          </span>

          <div class="row p-0 m-0 justify-content-end">
            <CustomAddButton
              text="Ajouter un Contributeur"
              class="px-2 px-md-4"
              :disabled="true"
            />
          </div>
        </div>
        <div class="col-12 p-0">
          <hr class="mt-1 mb-3 bg-secondary" />
        </div> -->
        <div class="row">
          <div class="col-12">
            <ContributerTable
              disabled
              :responsables="responsables"
              :contributers="[]"
            />
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import ContributerTable from "./components/ContributerTable.vue";
import ModalActions from "@/components/custom/ModalActions.vue";
import FilePicker from "@/components/custom/FilePicker.vue";
// import VueSlideBar from "vue-slide-bar";
import moment from "moment";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { required, minLength } from "vuelidate/lib/validators"; //validation
// import CustomAddButton from "@/components/custom/CustomAddButton";

export default {
  components: {
    ContributerTable,
    ModalActions,
    FilePicker,
    // CustomAddButton,
    // VueSlideBar,
  },
  data: () => ({
    selectedPhase: null,
    selectedResponsable: null,
    informatedDate: "",
    submitingForm: false,
    newTask: {
      echeance: "N",
      libelle: "",
      description: "",
      type: "",
      contributions: [],
      responsable: "",
      deadline: new Date(),
      status: "",
      startedAt: "",
      avancement: 0,
    },
    livrables: [],
    selectedInitiative: null,
  }),
  validations: {
    selectedPhase: {
      required,
    },
    selectedInitiative: {
      required,
    },
    // selectedResponsable: {
    //   required,
    // },
    informatedDate: {
      required,
      minValue(val) {
        return this.newTask.startedAt
          ? moment(val, "DD/MM/YYYY").diff(
              moment(this.newTask.startedAt, "DD/MM/YYYY")
            ) +
              1 >
              0
          : true;
      },
    },
    newTask: {
      libelle: {
        required,
        minLength: minLength(5),
      },
      type: {
        required,
      },
      deadline: {
        required,
      },
      status: {
        required,
      },
      startedAt: {
        required,
      },
    },
  },
  methods: {
    filePickerChanger(files) {
      this.livrables = files;
    },
    saveItem: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.submitingForm = true;
        this.$store.dispatch("task/createTask", {
          ...this.newTask,
          responsable: this.selectedResponsable
            ? `/api/users/${this.selectedResponsable}`
            : null,
          phase: this.selectedPhase,
          deadline: moment(this.informatedDate).format("DD/MM/YYYY"),
          startedAt: moment(this.newTask.startedAt).format("DD/MM/YYYY"),
        });
      }
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    // this.$store.dispatch("cStatus/fetchAllStatusTasks");
    this.$store.dispatch("cType/fetchTaskTypes");
    this.$store.dispatch("phase/fetchAllPhases");
    this.$store.dispatch("initiative/fetchAllInitiatives");

    this.$store.dispatch("cStatus/fetchAllStatusByType", "Task");

    if (this.$route.params.fromInitiative) {
      this.selectedInitiative = this.INITIATIVE["@id"];
      this.selectedPhase = this.INITIATIVE.phases.find(
        (phase) => phase.isCurrent
      )["@id"];
    }
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("cStatus", ["STATUS_TASKS"]), //get all status
    ...mapGetters("cType", ["TASK_TYPES"]), //get all status
    ...mapGetters("phase", ["PHASES"]), //get all phases
    ...mapGetters("task", ["TASK"]),
    ...mapGetters("initiative", ["INITIATIVE", "INITIATIVES"]), //get all phases
    ...mapGetters(["AuthenticatedUser"]), //get all phases

    responsables() {
      //return array of responsables
      return this.RESPONSABLES.map((responsable) => ({
        id: `/api/users/${responsable.id}`,
        full_name: responsable.firstname + " " + responsable.lastname,
        value: responsable.id,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    statusTasks() {
      return this.STATUS_TASKS;
    },
    typesTask() {
      return this.TASK_TYPES.map(({ name }) => name);
    },
    totalETP() {
      return 0;
    },
    initiativesList() {
      return this.INITIATIVES.map((initiative) => ({
        text: initiative.libelle,
        value: initiative["@id"],
      }));
    },

    phasesList() {
      if (!this.selectedInitiative) return [];
      return this.INITIATIVES.find(
        (initiative) => initiative["@id"] === this.selectedInitiative
      ).phases.map((phase) => ({
        value: phase["@id"],
        text: phase.libelle,
      }));
    },
  },
  watch: {
    selectedInitiative() {
      if (!this.selectedInitiative) {
        this.selectedPhase = null;
      }
    },
    TASK() {
      this.submitingForm = false;

      this.livrables.map((file) => {
        file.append("task", this.TASK["@id"]);
        file.append("collaborateur", this.AuthenticatedUser["@id"]);
        file.append("name", `Livrable ${moment().format("h:m:s DD/MM/YYYY")}`);
        this.$store.dispatch("livrable/createLivrable", file);
      });
      Swal.fire({
        title: "La tâche est bien créée !",
        type: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.value) {
          this.$router.push({
            name: "show-task",
            params: {
              slug: this.TASK.slug,
              id: this.TASK.id,
              fromCreation: true,
            },
          });
        }
      });
    },
  },
};
</script>

<style>
.mx-datepicker {
  width: 100% !important;
}
.form-row div {
  margin: auto;
}
.mx-datepicker .mx-input-wrapper .mx-input {
  height: 44.22px !important;
}
.custom-select {
  height: 44.22px !important;
}
</style>
